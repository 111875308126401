<template>
  <div class="container">
    <div class="page-title">
      <i class="el-icon-back" @click="back"  style="cursor:pointer"></i>
      <span style="padding-left: 8px;">图片库</span>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 150px;">请选择查询日期</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="dateArr"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </div>
          <div class="date-box" style="padding-left: 8px;">
            <div class="label" style="width: 60px;">关键字</div>
            <el-input
              style="width: 150px;"
              placeholder="请输入菜名"
              v-model="orderNo"
              clearable>
            </el-input>
          </div>
          <div class="date-box">
            <el-button type="primary" size="small" icon="el-icon-search" @click="search('only')">查询该类</el-button>
            <el-button type="success" size="small" icon="el-icon-search" @click="search('all')">查询全部</el-button>
            <el-button type="primary" size="small" icon="el-icon-delete" @click="reset">重置筛选</el-button>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <div style="text-align: center;font-size: 14px;color: #909399;margin-top: 10px;" v-if="!(tableData && tableData.length > 0)">暂无数据</div>
        <div class="food-content">
          <div class="food-box" v-for="(item,index) in tableData" :key="index">
            <img class="food-image" :src=" baseURL + '/images'+item.imgSmallUrl" alt="">
            <div class="image-size">
              <div>
                <span class="image-label">图片大小:</span>
                <span class="image-value">{{item.downSize | changeSize}}</span>
              </div>
              <div>
                <span class="image-label">调用次数:</span>
                <span class="image-value">{{item.downCount}}</span>
              </div>
            </div>
            <div class="image-desc">
              <div class="big-type">{{item.lastName || '暂无'}}</div>
              <div class="image-info">
                <div class="info-item">
                  <div style="border-right: 3px solid #ccc;">{{item.firstName || '暂无'}}</div>
                  <div>{{item.secondName || '暂无'}}</div>
                </div>
                <i class="el-icon-download" @click="downloadIamge(item.imgUrl)"></i>
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 10, 20, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '昨天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                picker.$emit('pick', [start, end])
              }
            },
            {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        orderNo:'', //查询
        tableData: [],
        dateArr: [],
        value: '',
        total: 0,
        pageSize: 12,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        firstId: '',
        searchType: '',
        baseURL: ''
      };
    },
    activated () {
      this.baseURL = window.baseURL;
      this.firstId = this.$route.query.firstId;
      this.queryOrderList(this.currentPage,this.pageSize);
    },
    methods: {
      back(){
        this.$router.go(-1);
      },
      queryOrderList(currentPage,pageSize){
        console.log('this.dateArr,this.orderNo',this.dateArr,this.orderNo,)
        let data = {
          lastName: this.orderNo || ''
        };
        if(this.searchType !== 'all'){
          data.firstId = this.firstId
        }
        if(this.dateArr && this.dateArr.length > 0){
          data.startTime = this.dateArr[0] || '';
          data.endTime = this.dateArr[1] || '';
        }
        this.listLoading = true;
        this.$http.post(`/pic/findFormalImgByPage/${currentPage}/${pageSize}`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      search(type){
        this.searchType = type;
         this.currentPage = 1;
         this.queryOrderList(this.currentPage,this.pageSize);
      },
      reset(){
        this.orderNo = '';
        this.dateArr = [];
        this.currentPage = 1;
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      downloadIamge(imgsrc, name) {//下载图片地址和图片名
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = this.baseURL + '/images'+ imgsrc;
      },
    },
    watch: {

    },
    filters: {
      changeSize(limit){
         var size = "";
        if(limit < 0.1 * 1024){                            //小于0.1KB，则转化成B
           size = limit.toFixed(2) + "B"}
        else if(limit < 0.1 * 1024 * 1024){            //小于0.1MB，则转化成KB
          size = (limit/1024).toFixed(2) + "KB"
        }else if(limit < 0.1 * 1024 * 1024 * 1024){        //小于0.1GB，则转化成MB
          size = (limit/(1024 * 1024)).toFixed(2) + "MB"
        }else{                                            //其他转化成GB
          size = (limit/(1024 * 1024 * 1024)).toFixed(2) + "GB"
        }
        var sizeStr = size + "";                        //转成字符串
        var index = sizeStr.indexOf(".");                    //获取小数点处的索引
        var dou = sizeStr.substr(index + 1 ,2)            //获取小数点后两位的值
        if(dou == "00"){                                //判断后两位是否为00，如果是则删除00
          return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
        }
        return size;
        }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .food-content {
    flex:1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 50px;
    font-size: 12px;
    overflow: auto;
  }
  .food-box {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin: 0 40px 20px 0;
    position: relative;
  }
  .food-image {
    width: 100%;
    height: 200px;
    object-fit: contain;;//转化不同类型
    /*padding: 3px;*/
    /*background-color: #999;*/
    border: 3px solid #999;
    box-sizing: border-box;
  }
  .image-size {
    display: flex;
    background-color: #999;
    padding: 6px 0;
    div {
      width: 50%;
      text-align: left;
      display: flex;
      align-items: center;
      border-radius: 3px;
      .image-label {
        display: flex;
        background-color: #ccc;
        width: 55px;
        padding: 3px;
        border-radius: 10px 0 0 10px;
      }
      .image-value {
        display: flex;
        flex: 1;
        background-color: #fff;
        padding: 3px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .image-desc {
    display: flex;
    flex-direction: column;
    .big-type {
      font-size: 14px;
      text-align: left;
      padding: 3px 0 3px 10px;
      border: 3px solid #ccc;
    }
    .image-info {
      font-size: 14px;
      border: 3px solid #ccc;
      display: flex;
      align-items: center;
      background-color: #ccc;
      .info-item{
        flex: 1;
        display: flex;
        background-color: #fff;
        margin-right: 5px;
        div {
          width: 50%;
        }
      }
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
</style>
